table.dataTable.dtr-inline.collapsed>tbody>tr>td.child, table.dataTable.dtr-inline.collapsed>tbody>tr>th.child, table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty {
	cursor: default !important
}
table.dataTable.dtr-inline.collapsed>tbody>tr>td.child:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.child:before, table.dataTable.dtr-inline.collapsed>tbody>tr>td.dataTables_empty:before {
	display: none !important
}
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child {
	position: relative;
	padding-left: 30px;
	cursor: pointer
}
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>th:first-child:before {
	top: 12px;
	left: 4px;
	height: 14px;
	width: 14px;
	display: block;
	position: absolute;
	color: white;
	border: 2px solid white;
	border-radius: 14px;
	box-shadow: 0 0 3px #444;
	box-sizing: content-box;
	text-align: center;
	text-indent: 0 !important;
	line-height: 14px;
	content: '+';	
}
table.dataTable.dtr-inline.collapsed>tbody>tr.parent>td:first-child:before, table.dataTable.dtr-inline.collapsed>tbody>tr.parent>th:first-child:before {
	content: '-';
	background-color: #d33333
}
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child, table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child {
	padding-left: 27px
}
table.dataTable.dtr-inline.collapsed.compact>tbody>tr>td:first-child:before, table.dataTable.dtr-inline.collapsed.compact>tbody>tr>th:first-child:before {
	top: 5px;
	left: 4px;
	height: 14px;
	width: 14px;
	border-radius: 14px;
	line-height: 14px;
	text-indent: 3px
}
table.dataTable.dtr-column>tbody>tr>td.control, table.dataTable.dtr-column>tbody>tr>th.control {
	position: relative;
	cursor: pointer
}
table.dataTable.dtr-column>tbody>tr>td.control:before, table.dataTable.dtr-column>tbody>tr>th.control:before {
	top: 50%;
	left: 50%;
	height: 16px;
	width: 16px;
	margin-top: -10px;
	margin-left: -10px;
	display: block;
	position: absolute;
	color: white;
	border: 2px solid white;
	border-radius: 14px;
	box-shadow: 0 0 3px #444;
	box-sizing: content-box;
	text-align: center;
	text-indent: 0 !important;
	font-family: 'Courier New', Courier, monospace;
	line-height: 14px;
	content: '+';
	background-color: #0275d8
}
table.dataTable.dtr-column>tbody>tr.parent td.control:before, table.dataTable.dtr-column>tbody>tr.parent th.control:before {
	content: '-';
	background-color: #d33333
}
table.dataTable>tbody>tr.child {
	padding: 0.5em 1em
}
table.dataTable>tbody>tr.child:hover {
	background: transparent !important
}
table.dataTable>tbody>tr.child ul.dtr-details {
	display: inline-block;
	list-style-type: none;
	margin: 0;
	padding: 0
}
table.dataTable>tbody>tr.child ul.dtr-details>li {
	border-bottom: 1px solid #efefef;
	padding: 0.5em 0
}
table.dataTable>tbody>tr.child ul.dtr-details>li:first-child {
	padding-top: 0
}
table.dataTable>tbody>tr.child ul.dtr-details>li:last-child {
	border-bottom: none
}
table.dataTable>tbody>tr.child span.dtr-title {
	display: inline-block;
	min-width: 75px;
	font-weight: bold
}
div.dtr-modal {
	position: fixed;
	box-sizing: border-box;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	padding: 10em 1em
}
div.dtr-modal div.dtr-modal-display {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 50%;
	height: 50%;
	overflow: auto;
	margin: auto;
	z-index: 102;
	overflow: auto;
	background-color: #f5f5f7;
	border: 1px solid black;
	border-radius: 0.5em;
	box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6)
}
div.dtr-modal div.dtr-modal-content {
	position: relative;
	padding: 1em
}
div.dtr-modal div.dtr-modal-close {
	position: absolute;
	top: 6px;
	right: 6px;
	width: 22px;
	height: 22px;
	border: 1px solid #eaeaea;
	background-color: #f9f9f9;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	z-index: 12
}
div.dtr-modal div.dtr-modal-close:hover {
	background-color: #eaeaea
}
div.dtr-modal div.dtr-modal-background {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 101;
	background: rgba(0, 0, 0, 0.6)
}
@media screen and (max-width: 767px) {
	div.dtr-modal div.dtr-modal-display {
		width: 95%
	}
}
div.dtr-bs-modal table.table tr:first-child td {
	border-top: none
}