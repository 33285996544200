.load {
  text-align: center;
  padding: 50px;
}

.container {
  margin-bottom: 3rem;
  overflow: auto;
  max-width: 100%;
  padding: 1.3rem;
  background: #fff;
  display: inline-block;
  border-radius: 10px;
}

:global(.dark-mode) .container {
  background: #272D47;
}

.container>select {
  margin-bottom: 1.5rem;
  max-width: 200px;
}

.grid {
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: repeat(5, 180px);
  grid-template-rows: repeat(5, 180px);
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(5, 200px);
    grid-template-rows: repeat(5, 200px);
  }
}

.grid>div {
  background-color: #ddd;
  cursor: pointer;
  display: grid;
  place-items: center;
  position: relative;
  background-size: contain;
}

:global(.dark-mode) .grid>div {
  background-color: #1E2339;
}

.notAvailable {
  color: #0096B1;
  font-family: Roboto;
  font-size: 49px;
  user-select: none;
}

.caption {
  position: absolute;
  bottom: 0.5rem;
  left: 50%;
  translate: -50% 0;
  color: rgba(213, 218, 226, 0.10);
  font-size: 8px;
}

.btn {
  margin-bottom: 0.5rem;
  font-size: 0.7rem;
  font-weight: semibold;
  padding: 0px;
  width: 70px;
  padding: 5px;
  line-height: 1;
}
