li.profit:before {
  border-color: #2cce8a !important;
}

li.deposit:before {
  border-color: #0096b1 !important;
}

li.withdrawal:before {
  border-color: #fdbf01 !important;
}

li.company-cost:before,
li.cost:before {
  border-color: #ff5d50 !important;
}

.amount-profit_text:hover,
.profit_text {
  color: #2cce8a !important;
}
.amount-deposit_text:hover,
.deposit_text {
  color: #0096b1 !important;
}

.amount-withdrawal_text:hover,
.withdrawal_text {
  color: #fdbf01 !important;
}

.amount-cost_text:hover,
.amount-company-cost_text:hover,
.cost_text,
.company-cost_text {
  color: #ff5d50 !important;
}
