.editor-wrapper.rdw-editor-wrapper {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.post-editor.rdw-editor-main {
  padding: 10px;
  padding-top: 0;
  min-height: 300px;
}

.rdw-editor-toolbar {
  color: black;
}
