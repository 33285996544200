.searchCon {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 999999999999;
  background: white;
  border: 1px solid #eee;
  max-height: 400px;
  overflow-y: auto;
}

.searchCon li {
  padding: 0.8rem 0.5rem;
}

.searchCon li:hover {
  background-color: #ededed;
  cursor: pointer;
}

@media (max-width: 575.98px) {
  .searchCon {
    top: calc(100% - 20px);
    width: calc(100% - 26px);
  }
}