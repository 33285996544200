.blog-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blog-posts.invisible {
  visibility: hidden;
}

.blog-loading {
  height: 50vh;
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
}

.post-card {
  max-width: 350px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 20px;
}

.post-card img {
  min-height: 250px;
}

.post-card:hover {
  opacity: 0.9;
}

.post-card-body {
  padding: 10px;
  background: white;
  color: #222;
}

.post-card-body h2, 
.post-card-body p {
  margin: 0;
}


body.dark-mode .post-card {
  border: 1px solid #343a40;
  /* box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.06); */
}

body.dark-mode  .post-card-body {
  background: #1a1c33;
  color: white;
}


.new-post_badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background: red;
  border-radius: 3px;
  padding: 0px 3px;
  line-height: 1;
  font-weight: bold;
}

.post-card:hover .new-post_badge {
  color: red;
  background: white;
}
