.login-container {
  background-color: #011d45;
  background-image: url(./assets/car-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 500px) {
  .login-container {
    background-position: cover;
  }
}

.font-small {
  font-size: 12px;
}

.notify {
  display: inline-grid;
  width: 25px;
  height: 25px;
  place-items: center;
  background: red;
  color: white;
  border-radius: 50%;
  line-height: 1;
  font-size: 0.9rem;
  font-weight: bold;
}

.indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #999;
}

.indicator.green {
  background: green;
}

.terms {
  max-width: 900px;
  margin: 0 auto;
  font-size: 16px;
}
.terms p {
  line-height: 1.5;
  margin-bottom: 35px;
}

.terms li {
  margin: 10px 0;
}

.terms .title {
  /* color: #42639f; */
  color: #6093ef;
  font-size: 24px;
}

.terms h2 {
  font-size: 20px;
  margin-bottom: 40px;
}

.terms ol {
  padding-left: 50px;
}
