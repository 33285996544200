.sidebar-closer-mobile {
  display: none;
}

@media (max-width: 550px) {
  .sidebar-closer-mobile {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
}

.appSidebar {
  overflow-y: auto;
}

.appSidebar::-webkit-scrollbar {
  display: none;
}
