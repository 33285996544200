.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index:9999;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.container {
  width: min(95%, 300px);
  display: none;
  z-index:99999;
  position: fixed;
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  border-radius: 34px;
  background: #272D47;
  padding: 27px 16px;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.btns button:first-child {
  background: #bbb;
}

.active {
  display: block;
}

.container h2 {
  font-size: 26px;
  text-align: center;
}

.success {
  color: green;
  margin-top: 1rem;
}

.error {
  color: red;
  margin-top: 1rem;
}
