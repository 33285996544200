$background:#ebeef1;
$color:#1a1630;

$dark-theme:#1a1c33;



/*Color variables*/
$primary:#0096b1; 
$secondary:#fb1c52;
$pink:#ec82ef;
$teal:#2bcbba;
$cyan:#17a2b8;
$purple:#aa4cf2;
$success:#38cb89;
$warning:#ffab00;
$danger:#ef4b4b;
$info:#5b7fff;
$orange:#fc7303;
$dark:#343a40;
$indigo:#6574cd;
$gray:#868e96;
$white:#fff;
$black:#000;



/*border variables*/
$border:#ebecf1;

/*shadow variables*/
$shadow:0px 6px 8px rgba(4, 4, 7, 0.1);


/*Colors*/
$primary2:#2ec49f; 
$primary3:#0062cd;
$primary4:#f66347;
$primary5:#06bfd3;




	

