.modal,
.popup {
  position: fixed;
  overflow: hidden;
  z-index: 999999999999999999999999999999999999999999;
}

.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  transition: opacity 200ms;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.popup {
  top: 50%;
  left: 50%;
  border-radius: 10px;
  width: min(90%, 500px);
  height: auto;
  scale: 0;
  translate: -50% -50%;
  transition: scale 200ms;
  background: white;
}

.modal.active {
  display: block;
  opacity: 1;
}

.popup.active {
  scale: 1;
}

@media (min-width: 550px) {
  .fixupMargin {
    margin-bottom: -35px;
    position: relative;
    z-index: 9;
  }
}

@media (max-width: 500px) {
  .fixupMargin {
    display: block;
    margin: 1rem auto;
    width: max-content
  }
}
