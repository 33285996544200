.signupForm  {
  max-height: 95vh;
  overflow-y: auto;
  padding: 2.5rem 1.5rem !important;
}

.signupForm::-webkit-scrollbar-thumb {
  background-color: #15395B !important;
}

.signupForm::-webkit-scrollbar,
.signupForm::-webkit-scrollbar-track {
  background-color: transparent !important;
}


.signupForm select,
.signupForm select option,
.signupForm input {
  background-color: #072d51 !important;
  border: 1px solid rgb(21 23 26) !important;
  border-radius: 10px !important;
  box-shadow: inset rgb(0 0 0 / 30%) 2px 2px 2.6px !important;
}

.inputIcon > div {
  background-color: #062d51 !important;
  border: none !important;
}
