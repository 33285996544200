.currencyContainer {
  overflow: auto;
  display: flex;
  margin: 1.3rem 0;
  scroll-behavior: smooth;
}


.currencyContainer::-webkit-scrollbar {
  height: 0;
  display: none;
}

.currencyCard {
  margin-right: 2rem;
  width: 285px;
  flex-shrink: 0;
  border-radius: 30px;
  display: inline-flex;
  gap: 0.5rem;
  padding: 1rem;
  align-items: center;
  font-size: 1rem;
  background: #fff;
}

body.dark-mode .currencyCard {
  background: #272D47;
}

.currencyCard :last-child {
  margin-right: 0;
}

.currencyCard > *:last-child {
  margin-left: auto;
}

.currencyUsd {
  font-size: 12px;
  color: #a1a1a1;
}

.currencyIcon {
  width: 24px;
  height: 24px;
}
