.qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  scale: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.qr-modal.open {
  scale: 1;
}

.qr-container {
  padding: 1rem;
  z-index: 10000;
  position: fixed;
  top: 50%;
  left: 50%;
  width: min(90%, 300px);
  height: min(90vh, max-content);
  translate: -50% -50%;
  scale: 0;
  transition: scale 200ms;
  background: white;
  border-radius: 10px;
}

.qr-container.open {
  scale: 1;
}

.qr-container h2 {
  text-align: center;
  color: black;
}

.qr-container img {
  width: 100%;
}
