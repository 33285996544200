.partner {
  position: relative;
  width: min(100%, 477px);
  overflow: hidden;
  border-radius: 18px;
  height: 441px;
  background-image: url(../../assets/partners/gchat.png);
  background-size: cover;
  background-position: -140px -30px;
  background-repeat: no-repeat;
}

.partner-type {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 10px 30px;
  border-radius: 18px;
  color: #00FFB3;
  font-size: 12px;
  line-height: 20px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(8px);
}

.partner-title {
  font-size: 29px;
  font-weight: 400;
  margin-bottom: 8px;
}

.partner-footer {
  padding: 33px 14px 10px 14px;
  background: rgba(0, 0, 0, 0.10);
  backdrop-filter: blur(47px);
  position: absolute;
  bottom: 0;
  left: 0;
}


.partner-footer p {
  line-height: 20px;
  margin-top: 0px;
  margin-bottom: 0;
}

.partner-link {
  float: right;
  padding: 10px 30px;
  border-radius: 20px;
  background: #00FFA8;
  color: #272D47;
  text-decoration: underline;
}
